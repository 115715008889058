import {Injectable} from '@angular/core';
import {Network} from '@capacitor/network';
import {BehaviorSubject, concat, from, iif, Observable, of, scheduled, Subject, throwError, zip} from 'rxjs';
import {ApiService} from './api.service';
import {CacheService} from './cache.service';
import {
  catchError, concatAll,
  concatMap,
  defaultIfEmpty, delay,
  filter,
  map, mergeMap,
  retry,
  skip,
  switchMap,
  take,
  tap,
  toArray
} from 'rxjs/operators';
import {CalendarRes, CalendarStateReq, DocumentRes} from '../models/calendar/calendarRes';
import {LoadingController, Platform} from '@ionic/angular';
import {ContactRes} from '../models/contact/contactRes';
import {OrderRes} from '../models/order/orderRes';
import {UiHelperService} from './ui-helper.service';
import {GiftRes, GiftsRes} from '../models/gift/giftRes';
import {CalendarGiftsReq} from '../models/gift/giftReq';
import {LoginService} from './login.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CalendarStatsData, CalendarStatsReq} from '../models/calendarStats/calendarStatsReq';
import {ContactReq} from '../models/contact/ContactReq';
import {OrderReq} from '../models/order/OrderReq';
import {fromArray} from 'rxjs/internal/observable/fromArray';


@Injectable({
  providedIn: 'root'
})
export class SyncService {
  networkStatus$ = new BehaviorSubject({connected: true, connectionType: 'wifi'});
  url = `${environment.apiUrl}/RispettoAppServlet`;
  loading: any;
  offlineLoading: any;

  constructor(
    private apiService: ApiService,
    private cacheService: CacheService,
    private loadingCtrl: LoadingController,
    private uiHelperService: UiHelperService,
    private platform: Platform,
    private loginService: LoginService,
    private router: Router,
    private http: HttpClient,
  ) {
  }

  async networkChangeDetection() {
    this.offlineLoading = await this.loadingCtrl.create({
      message: 'Izvanmrežni način rada, čeka se veza',
      spinner: 'bubbles'
    });

    Network.addListener('networkStatusChange', async status => {
      this.networkStatus$.next(status);
      this.apiService.networkStatus$.next(status);
    });

    this.networkStatus$.subscribe(networkStatus => {
      if (networkStatus.connected) {
        // this.syncAllData();
        // this.uiHelperService.presentToast('Späť online');
      } else {
        this.uiHelperService.presentToast(
          'Veza izgubljena: izvanmrežni način rada');
      }

      if (!networkStatus.connected) {
        this.offlineLoading.present();
      } else {
        this.offlineLoading.dismiss();
        this.loadingCtrl.create({
          message: 'Izvanmrežni način rada, čeka se veza',
          spinner: 'bubbles',
          // duration: 100
        }).then(
          loading => {
            this.offlineLoading = loading;
          }
        );
      }
    });
  }

  syncAllData() {
    console.log('Synchronizujem');
    this.loadingCtrl.create({
      message: 'Prebieha synchronizácia',
      spinner: 'bubbles'
    }).then(loading => {
        loading.present();
        this.syncCalendar().pipe(
          switchMap(() => this.syncContacts()),
          switchMap(() => this.syncRecommendations()),
          switchMap(() => this.syncOrders()),
          switchMap(() => this.syncCalendarFiles()),
          switchMap(() => this.syncContactsFiles()),
          switchMap(() => this.syncOrdersFiles()),
        ).subscribe(
          () => {
            console.log('sync done');
            loading.dismiss();
          },
          error => {
            console.warn(error);
            loading.dismiss();
          },
          () => {
            loading.dismiss();
          }
        );
      }
    );
  }

  syncCalendar() {
    return this.apiService.getCachedInitData()
      .pipe(
        tap(() => console.log('Synchronizujem kalendare')),
        map(result => Object.values(result.calendars)),
        map(result => [].concat.apply([], result)),
        map(calendars => calendars.filter(calendar => calendar !== undefined)),
        map(result => result.filter(calendar => calendar.unsynced)),
        concatMap(result => iif(() => result.length > 0, from(result), of(null))),
        map((unsyncedCalendar: CalendarRes | null) => {
          console.log('Unsynced calendar: ', unsyncedCalendar);
          if (unsyncedCalendar != null) {
            return zip(
              this.syncCalendarGift(unsyncedCalendar).pipe(take(1)),
              this.syncCalendarStats(unsyncedCalendar).pipe(take(1)),
              this.syncCalendarState(unsyncedCalendar).pipe(take(1)),
            ).pipe(
              switchMap(result => {
                this.cacheService.changeSyncStatusCalendar(this.url, unsyncedCalendar).subscribe();
                return of(true);
              }),
              catchError(err => of(false))
            );
          } else {
            return of(false);
          }
        }),
        concatAll(),
      );
  }

  syncCalendarGift(oldCalendar: CalendarRes) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$.pipe(
        take(1),
        switchMap(connection => {
          if (connection.connected) {
            const calendarStatsReq: CalendarGiftsReq = {
              action: 'GIFTS',
              token,
              data: {
                id: oldCalendar.id,
                gifts: oldCalendar.gifts
              },
            };
            return this.http.post<GiftsRes>(this.url, calendarStatsReq)
              .pipe(
                catchError(err => throwError(this.errorHandler(err))),
                switchMap(giftResponse => this.cacheService.updateUnsyncedCalendarGifts(this.url, giftResponse.gifts, oldCalendar)),
              );
          } else {
            return of(null);
          }
        })
      ))
    );
  }


  syncCalendarStats(oldCalendar: CalendarRes) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            console.log(connection);
            if (connection.connected) {
              const calendarStatsReq: CalendarStatsReq = {
                action: 'SET_PHK',
                token,
                data: {
                  id: oldCalendar.id,
                  calendar_guests: oldCalendar.calendar_guests,
                  calendar_contacts: oldCalendar.calendar_contacts,
                  calendar_pairs: oldCalendar.calendar_pairs,
                  calendar_sms: oldCalendar.calendar_sms,
                },
              };
              return this.http.post(this.url, calendarStatsReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(() => this.cacheService.updateUnsyncedCalendarStats(this.url, oldCalendar)),
                );
            } else {
              return of(null);
            }
          })
        ))
    );
  }

  syncCalendarState(oldCalendar: CalendarRes) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const calendarStateReq: CalendarStateReq = {
                action: 'CALENDAR_STATE',
                token,
                data: {
                  id: oldCalendar.id,
                  state: oldCalendar.calendar_state_id
                },
              };
              return this.http.post<CalendarRes>(this.url, calendarStateReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(newCalendar => this.cacheService.changeSyncStateCalendar(this.url, newCalendar)),
                );
            } else {
              return of(null);
            }
          })
        ))
    );
  }


  syncContacts() {
    return this.apiService.getCachedInitData()
      .pipe(
        tap(() => console.log('Synchronizujem kontakty')),
        map(result => Object.values(result.calendars)),
        map(result => [].concat.apply([], result)),
        switchMap((calendars: CalendarRes[]) => of(calendars).pipe(
            map(calendars => calendars.filter(calendar => calendar !== undefined)),
            map(result => [].concat.apply([], result.map(calendar => calendar.contacts))),
            map(result => result.filter(contact => contact.unsynced)),
            concatMap(result => iif(() => result.length > 0, from(result), of(null))),
            map(unsyncedContact => {
                console.log('Unsynced contact: ', unsyncedContact);
                if (unsyncedContact !== null) {
                  return zip(
                    this.syncContact(unsyncedContact, calendars).pipe(take(1))
                  ).pipe(
                    switchMap(() => of(true)),
                    catchError(error => {
                      console.warn(error);
                      return of(false);
                    })
                  );
                } else {
                  return of(false);
                }
              }
            ),
            concatAll(),
          ))
      );
  }

  syncRecommendations() {
    return this.apiService.getCachedInitData()
      .pipe(
        tap(() => console.log('Synchronizujem odporucania')),
        map(result => Object.values(result.calendars)),
        map(result => [].concat.apply([], result)),
        switchMap((calendars: CalendarRes[]) => of(calendars).pipe(
            map(calendars => calendars.filter(calendar => calendar !== undefined)),
            map(result => [].concat.apply([], result.map(calendar => calendar.recommendations))),
            map(result => result.filter(recommendation => recommendation.unsynced)),
            concatMap(result => iif(() => result.length > 0, from(result), of(null))),
            map(unsyncedRecommendation => {
                console.log('Unsynced recommendation: ', unsyncedRecommendation);
                if (unsyncedRecommendation !== null) {
                  return zip(
                    this.syncContact(unsyncedRecommendation, calendars, true).pipe(take(1))
                  ).pipe(
                    switchMap(() => of(true)),
                    catchError(error => {
                      console.warn(error);
                      return of(false);
                    })
                  );
                } else {
                  return of(false);
                }
              }
            ),
            concatAll(),
          ))
      );
  }

  syncOrders() {
    return this.apiService.getCachedInitData()
      .pipe(
        tap(() => console.log('Synchronizujem objednavky')),
        map(result => Object.values(result.calendars)),
        map(result => [].concat.apply([], result)),
        switchMap((calendars: CalendarRes[]) => of(calendars).pipe(
            map(calendars => calendars.filter(calendar => calendar !== undefined)),
            map(result => [].concat.apply([], result.map(calendar => calendar.contacts))),
            map(result => [].concat.apply([], result.map(contact => contact.orders))),
            map(result => result.filter(order => order.unsynced)),
            concatMap(result => iif(() => result.length > 0, from(result), of(null))),
            map((unsyncedOrder: OrderRes) => {
                console.log('Unsynced order: ', unsyncedOrder);
                if (unsyncedOrder !== null) {
                  return zip(
                    this.syncOrder(unsyncedOrder, calendars).pipe(take(1))
                  ).pipe(
                    switchMap(() => of(true)),
                    catchError(error => {
                      console.warn(error);
                      return of(false);
                    })
                  );
                } else {
                  return of(false);
                }
              }
            ),
            concatAll(),
          ))
      );
  }

  syncContact(contact: ContactRes, calendars: CalendarRes[], recommendation: boolean = false) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              calendars = calendars.filter(calendar => calendar !== undefined);
              let oldCalendar = null;
              if (!recommendation) {
                oldCalendar = calendars.filter(calendar => calendar.contacts.find(contact2 => contact2.id == contact.id))[0];
              } else {
                oldCalendar = calendars.filter(calendar => calendar.recommendations.find(recommendation2 => recommendation2.id == contact.id))[0];
              }
              const action = (recommendation ? 'RECOMMENDATION' : 'CONTACT');
              contact.oldId = contact.id;
              if (Number(contact.id) === Number(contact.mobile.toString().replace('+', ''))) {
                contact.id = 0;
              }
              const contactReq: ContactReq = {
                action,
                token,
                data: {
                  id: oldCalendar.id,
                  contact
                },
              };
              console.log(contactReq);
              return this.http.post<ContactRes>(this.url, contactReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(newContact => this.cacheService.updateUnsyncedContact(this.url, newContact, contact, oldCalendar, recommendation)),
                );
            } else {
              return of(null);
            }
          })
        ))
    );
  }

  syncOrder(order: OrderRes, calendars: CalendarRes[]) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              calendars = calendars.filter(calendar => calendar !== undefined);
              const oldCalendar = calendars.filter(calendar => calendar.contacts.find(contact2 => contact2.id == order.contact_id))[0];
              const contact = oldCalendar.contacts.find(contact => contact.id === order.contact_id);
              order.oldId = order.id;
              if (order.unsynced && (order.id.toString().includes(contact.mobile.replace('+', '')) || order.id.toString().includes(contact.id.toString()))) {
                order.id = 0;
              }
              const orderReq: OrderReq = {
                action: 'ORDER',
                token,
                data: {
                  id: oldCalendar.id,
                  order,
                },
              };
              console.log(orderReq);
              return this.http.post(this.url, orderReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap((newOrder: OrderRes) => this.cacheService.updateUnsyncedOrder(this.url, newOrder, order, oldCalendar))
                );
            } else {
              return of(null);
            }
          })
        ))
    );
  }


  syncCalendarFiles() {
    return this.apiService.getCachedInitData()
      .pipe(
        tap(() => console.log('Synchronizujem subory kalendara')),
        map(result => Object.values(result.calendars)),
        map(result => [].concat.apply([], result)),
        switchMap((calendars: CalendarRes[]) => of(calendars).pipe(
            map(calendars => calendars.filter(calendar => calendar !== undefined)),
            map(result => [].concat.apply([], result.map(calendar => calendar.documents))),
            map(result => result.filter(document => document.unsynced)),
            concatMap(result => iif(() => result.length > 0, from(result), of(null))),
            map((unsyncedFile: DocumentRes) => {
                console.log('Unsynced document: ', unsyncedFile);
                if (unsyncedFile !== null) {
                  calendars = calendars.filter(calendar => calendar !== undefined);
                  const oldCalendar = calendars.filter(calendar => calendar.documents.find(document2 => document2.id == unsyncedFile.id))[0];
                  const blob = this.apiService.dataURItoBlob(unsyncedFile.base64, unsyncedFile.type);
                  const file: File = new File([blob], unsyncedFile.fileName, {type: unsyncedFile.type});
                  return this.syncNewFile(file, unsyncedFile.id, oldCalendar.id, 'business_calendar', null, oldCalendar)
                    .pipe(
                      take(1),
                      switchMap(() => of(true)),
                      catchError(error => {
                        console.warn(error);
                        return of(false);
                      })
                    );
                } else {
                  return of(false);
                }
              }
            ),
            concatAll(),
          ))
      );
  }

  syncContactsFiles() {
    return this.apiService.getCachedInitData()
      .pipe(
        tap(() => console.log('Synchronizujem subory kontaktov')),
        map(result => Object.values(result.calendars)),
        map(result => [].concat.apply([], result)),
        switchMap((calendars: CalendarRes[]) => of(calendars).pipe(
            map(calendars => calendars.filter(calendar => calendar !== undefined)),
            map(result => [].concat.apply([], result.map(calendar => calendar.contacts))),
            map(result => [].concat.apply([], result.map(contact => contact.documents))),
            map(result => result.filter(document => document.unsynced)),
            concatMap(result => iif(() => result.length > 0, from(result), of(null))),
            map((unsyncedFile: DocumentRes) => {
                console.log('Unsynced document: ', unsyncedFile);
                if (unsyncedFile !== null) {
                  calendars = calendars.filter(calendar => calendar !== undefined);
                  const oldCalendar = calendars.filter(calendar => calendar.contacts.find(contact => contact.documents.find(document => document.id === unsyncedFile.id)))[0];
                  const contact = oldCalendar.contacts.find(contact => contact.documents.find(document => document.id === unsyncedFile.id));
                  const blob = this.apiService.dataURItoBlob(unsyncedFile.base64, unsyncedFile.type);
                  const file: File = new File([blob], unsyncedFile.fileName, {type: unsyncedFile.type});
                  return this.syncNewFile(file, unsyncedFile.id, contact.id, 'contact', contact.id, oldCalendar)
                    .pipe(
                      take(1),
                      switchMap(() => of(true)),
                      catchError(error => {
                        console.warn(error);
                        return of(false);
                      })
                    );
                } else {
                  return of(false);
                }
              }
            ),
            concatAll(),
          ))
      );
  }

  syncOrdersFiles() {
    return this.apiService.getCachedInitData()
      .pipe(
        tap(() => console.log('Synchronizujem subory objednavok')),
        map(result => Object.values(result.calendars)),
        map(result => [].concat.apply([], result)),
        switchMap((calendars: CalendarRes[]) => of(calendars).pipe(
            map(calendars => calendars.filter(calendar => calendar !== undefined)),
            map(result => [].concat.apply([], result.map(calendar => calendar.contacts))),
            map(result => [].concat.apply([], result.map(contact => contact.orders))),
            map(result => [].concat.apply([], result.map(order => order.documents))),
            map(result => result.filter(document => document !== undefined)),
            map(result => result.filter(document => document.unsynced)),
            concatMap(result => iif(() => result.length > 0, fromArray(result), of(null))),
            map((unsyncedFile: DocumentRes) => {
                console.log('Unsynced document: ', unsyncedFile);
                if (unsyncedFile !== null) {
                  calendars = calendars.filter(calendar => calendar !== undefined);
                  let contacts = [].concat.apply([], calendars.map(calendar => calendar.contacts));
                  let orders = [].concat.apply([], contacts.map(contact => contact.orders));
                  orders = orders.filter(order => order.documents !== undefined);
                  const order = orders.filter(order => order.documents.find(document => document.id === unsyncedFile.id))[0];
                  contacts = contacts.filter(contact => contact.orders !== undefined);
                  const contact = contacts.filter(contact => contact.orders.find(contactOrder => contactOrder.id === order.id))[0];
                  const oldCalendar = calendars.filter(calendar => calendar.contacts.find(calendarContact => calendarContact.id === contact.id))[0];
                  const blob = this.apiService.dataURItoBlob(unsyncedFile.base64, unsyncedFile.type);
                  const file: File = new File([blob], unsyncedFile.fileName, {type: unsyncedFile.type});
                  return this.syncNewFile(file, unsyncedFile.id, order.id, 'orderin', contact.id, oldCalendar)
                    .pipe(
                      take(1),
                      switchMap(() => of(true)),
                      catchError(error => {
                        console.warn(error);
                        return of(false);
                      })
                    );
                } else {
                  return of(false);
                }
              }
            ),
            concatAll(),
          ))
      );
  }


  syncNewFile(file: File, oldId, documentId: number, documentType: string, contactId: number, calendar) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const formData: FormData = new FormData();
              formData.append('file', file, file.name);
              formData.append('fileName', file.name);
              formData.append('action', 'UPLOAD_FILE');
              formData.append('token', token);
              formData.append('data', JSON.stringify(
                {
                  id: documentId,
                  document: documentType,
                }));
              const headers = new HttpHeaders();
              headers.append('Content-Type', 'multipart/form-data');

              console.log(formData);
              return this.http.post(this.url, formData, {headers})
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap((result: DocumentRes) => {
                    console.log(documentId);
                    result.fileName = file.name;
                    result.name = file.name;
                    result.type = file.type;
                    switch (documentType) {
                      case 'orderin' :
                        return this.cacheService.updateCachedOrderDocument(this.url, documentId, contactId, result, oldId, calendar, true);
                      case 'business_calendar' :
                        return this.cacheService.updateCachedCalendarDocument(this.url, result, calendar, true);
                      case 'contact' :
                        return this.cacheService.updateCachedContactDocument(this.url, contactId, result, calendar, true);
                    }
                  }),
                );
            } else {
              return of(null);
            }
          })
        ))
    );
  }


  errorHandler(err) {
    console.warn(err);
    this.apiService.sendErrorByEmail(JSON.stringify(err)).subscribe();
    if (Number(err.status) === 401) {
      this.loginService.deleteAuthData().subscribe();
    }
    if (Number(err.status) === 405) {
      this.router.navigate([`/home`], {replaceUrl: true});
    }
    return err;
  }
}
