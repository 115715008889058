import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  CanLoad,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { LoginService } from '../services/login.service';
import {take, tap} from 'rxjs/operators';
import {ApiService} from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private apiService: ApiService,
    private router: Router
  ) {
  }

  canActivate(){
    return this.loginService.isLoggedIn().pipe(
      take(1),
      tap(
        result=> {
          if (!result){
            this.router.navigate(['/login']);
          }
        }
      ));
  }


}
