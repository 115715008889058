import {Component, OnInit} from '@angular/core';
import {CacheService} from './services/cache.service';
import {PushNotificationService} from './services/push-notification.service';
import {LoadingController, Platform} from '@ionic/angular';
import {SyncService} from './services/sync.service';
import {NavigationService} from './services/navigation.service';
import {ApiService} from './services/api.service';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {App} from '@capacitor/app';
import {Filesystem} from '@capacitor/filesystem';
import {SelfUpdateService} from './services/self-update.service';
import {UiHelperService} from './services/ui-helper.service';
import packageJson from '../../package.json';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  downloading = false;

  constructor(
    private cacheService: CacheService,
    private pushNotificationService: PushNotificationService,
    private syncService: SyncService,
    private navigationService: NavigationService,
    private apiService: ApiService,
    public platform: Platform,
    private appVersion: AppVersion,
    private loadingCtrl: LoadingController,
    private selfUpdateService: SelfUpdateService,
    private uiHelperService: UiHelperService
  ) {
    this.cacheService.initStorage();
    if (platform.is('capacitor')) {
      this.pushNotificationService.addListeners();
    }
    this.syncService.networkChangeDetection();
      App.addListener('appStateChange', ({isActive}) => {
        if (isActive) {
          this.updateApp();
        }
      });
      this.updateApp();
  }

  updateApp() {
    console.log(packageJson.version);

    if (this.platform.is('capacitor')) {
      this.selfUpdateService.getNewestVersion()
        .subscribe(
          newestVersion => {
            this.appVersion.getVersionNumber().then(async actVersion => {
              if (newestVersion.version.toString() !== actVersion.toString() && !this.downloading) {
                this.uiHelperService.presentToast(newestVersion.version.toString());
                let check = (await Filesystem.checkPermissions()).publicStorage;
                if (check !== 'granted') {
                  check = (await Filesystem.requestPermissions()).publicStorage;
                  if (check !== 'granted') {
                    App.exitApp();
                  } else {
                    await this.downloadNewUpdate();
                  }
                } else {
                  await this.downloadNewUpdate();
                }
              }
            });
          });
    } else {
      this.selfUpdateService.getNewestVersion()
        .subscribe(
          newestVersion => {
            if(newestVersion.version !== packageJson.version) {
              console.log('Update');
              // @ts-ignore
              window.location.reload(true);
            } else {
              console.log('Same version');
            }
          });
    }
  }


  async downloadNewUpdate(){
    const loading = await this.loadingCtrl.create({
      message: 'Aktualizujem aplikáciu, počkajte prosím',
      spinner: 'bubbles'
    });
    this.downloading = true;
    await loading.present();
    const interval = setInterval(() => {
      loading.message = 'Aktualizujem aplikáciu, počkajte prosím. <br> Prebieha sťahovanie : '
        + this.selfUpdateService.downloadProgress + ' %';
      if (this.selfUpdateService.downloadProgress >= 100) {
        clearInterval(interval);
        loading.message = 'Aktualizujem aplikáciu, počkajte prosím. <br> Prebieha príprava na inštaláciu';
      }
    }, 10);
    await this.selfUpdateService.getNewestUpdate();

  }
}
