import {Injectable} from '@angular/core';
import {AsyncSubject, BehaviorSubject, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CacheService} from './cache.service';
import {LoginService} from './login.service';
import {InitRes} from '../models/init/initRes';
import {GiftRes, GiftsRes} from '../models/gift/giftRes';
import {CalendarReq, CalendarRes, CalendarStateReq, DocumentRes} from '../models/calendar/calendarRes';
import {CalendarStatsData, CalendarStatsReq} from '../models/calendarStats/calendarStatsReq';
import {CalendarGiftsReq} from '../models/gift/giftReq';
import {ContactReq} from '../models/contact/ContactReq';
import {ContactRes, ZarenClubCardRes} from '../models/contact/contactRes';
import {OrderReq} from '../models/order/OrderReq';
import {OrderRes} from '../models/order/orderRes';
import {DatePipe} from '@angular/common';
import {Exports, ExportsRes} from '../models/exports/exports';
import {Warehouse, WarehouseRes} from '../models/warehouse/warehouse';
import {Router} from "@angular/router";
import {ErrorReq} from "../models/error/errorReq";
import { CalendarStatsRes } from '../models/calendar/calendarStatsRes';
import { MentorStats, MentorStatsRes } from '../models/mentorStats/MentorStats';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url = `${environment.apiUrl}/RispettoAppServlet`;
  networkStatus$ = new BehaviorSubject({connected:  true, connectionType: 'wifi'});

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
    private loginService: LoginService,
    private datePipe: DatePipe,
    private router: Router,
  ) {}
  getInitData(): Observable<InitRes | null> {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('token', token);
        queryParams = queryParams.append('action', 'INIT_DATA');
        return this.networkStatus$
          .pipe(
            take(1),
            switchMap(connection => {
              if (connection.connected) {
                return this.http.get<InitRes>(this.url, {params: queryParams}).pipe(
                  tap(res => console.log(res)),
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(result => {
                    if (result) {
                      this.loginService.isEditableBe = !result.readonly;
                      this.cacheService.cacheRequests(this.url, result, 2 * 60 * 60).subscribe();
                      const intrauserSubjectData = {calendars:result.calendars, orders: result.orders};
                      this.cacheService.cacheRequests('intrauserSubjectData', intrauserSubjectData, 2 * 60 * 60).subscribe();
                      return of(result);
                    } else {
                      return of(null);
                    }
                  })
                );
              } else {
                return this.getCachedInitData();
              }
            })
          );
      })
    );
  }

  getCalendarStats(oldCalendar: CalendarRes): Observable<CalendarStatsRes | null> {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('token', token);
        queryParams = queryParams.append('id', oldCalendar.id);
        queryParams = queryParams.append('action', 'CALENDAR_STATS');
        return this.http.get<CalendarStatsRes>(this.url, {params: queryParams})
          .pipe(
            catchError(err => throwError(this.errorHandler(err))),
            switchMap(calendarStats => this.cacheService.updateCachedCalendarStatsState(this.url, calendarStats, oldCalendar,true)),
          );
      })
    );
  }

  getSubjectData(subjectId: string): Observable<InitRes | null> {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
          let queryParams = new HttpParams();
          queryParams = queryParams.append('token', token);
          queryParams = queryParams.append('action', 'SUBJECT_DATA');
          queryParams = queryParams.append('id', subjectId);
          return this.networkStatus$
            .pipe(
              take(1),
              switchMap(connection => {
                if (connection.connected) {
                  return this.http.get<InitRes>(this.url, {params: queryParams}).pipe(
                    catchError(err => throwError(this.errorHandler(err))),
                    switchMap(result => {
                      return this.cacheService.cacheSubjectData(this.url, result);
                    })
                  );
                } else {
                  return of(null);
                }
              })
            );
        }
      ));
  }

  getZarenClubCard(contactId: number, oldCalendar: CalendarRes): Observable<string | null> {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
          let queryParams = new HttpParams();
          queryParams = queryParams.append('token', token);
          queryParams = queryParams.append('action', 'ZAREN_CARD');
          queryParams = queryParams.append('id', contactId);
          return this.networkStatus$
            .pipe(
              take(1),
              switchMap(connection => {
                if (connection.connected) {
                  return this.http.get<ZarenClubCardRes>(this.url, {params: queryParams}).pipe(
                    catchError(err => throwError(this.errorHandler(err))),
                    switchMap(result => {
                      return this.cacheService.cacheZarenClubCard(this.url, result.card_number, contactId, oldCalendar);
                    })
                  );
                } else {
                  return of(null);
                }
              })
            );
        }
      ));
  }

  getExports(subjectId: number, dateFrom: Date, dateTo: Date, lastProvision: boolean): Observable<Exports[] | null> {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
          let queryParams = new HttpParams();
          queryParams = queryParams.append('token', token);
          queryParams = queryParams.append('action', 'EXPORTS');
          queryParams = queryParams.append('id', subjectId);
          queryParams = queryParams.append('dateFrom', this.datePipe.transform(dateFrom, 'YYYY-MM-dd')); //YYYY-MM-DD
          queryParams = queryParams.append('dateTo', this.datePipe.transform(dateTo, 'YYYY-MM-dd'));
          queryParams = queryParams.append('provision', lastProvision);
          return this.networkStatus$
            .pipe(
              take(1),
              switchMap(connection => {
                if (connection.connected) {
                  return this.http.get<ExportsRes>(this.url, {params: queryParams})
                    .pipe(
                      map(exportsRes => exportsRes.data),
                      catchError(err => throwError(this.errorHandler(err))),
                    );
                } else {
                  return of(null);
                }
              })
            );
        }
      ));
  }

  getWarehouse(): Observable<Warehouse[] | null> {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
          let queryParams = new HttpParams();
          queryParams = queryParams.append('token', token);
          queryParams = queryParams.append('action', 'WAREHOUSE');
          return this.networkStatus$
            .pipe(
              take(1),
              switchMap(connection => {
                if (connection.connected) {
                  return this.http.get<WarehouseRes>(this.url, {params: queryParams})
                    .pipe(
                      map(warehouseRes => warehouseRes.products),
                      catchError(err => throwError(this.errorHandler(err))),
                    );
                } else {
                  return of(null);
                }
              })
            );
        }
      ));
  }

  getMentorStats(subjectId: number, dateFrom: Date, dateTo: Date): Observable<MentorStats[] | null> {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
          let queryParams = new HttpParams();
          queryParams = queryParams.append('token', token);
          queryParams = queryParams.append('action', 'MENTOR_STATS');
          queryParams = queryParams.append('id', subjectId);
          queryParams = queryParams.append('dateFrom', this.datePipe.transform(dateFrom, 'YYYY-MM-dd'));
          queryParams = queryParams.append('dateTo', this.datePipe.transform(dateTo, 'YYYY-MM-dd'));
          return this.networkStatus$
            .pipe(
              take(1),
              switchMap(connection => {
                if (connection.connected) {
                  return this.http.get<MentorStatsRes>(this.url, {params: queryParams})
                    .pipe(
                      map(mentorStatsRes => mentorStatsRes.data),
                      catchError(err => throwError(this.errorHandler(err))),
                    );
                } else {
                  return of(null);
                }
              })
            );
        }
      ));
  }

  getCachedInitData(): Observable<InitRes | null> {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
        if (token) {
          return this.cacheService.getCachedRequest(this.url).pipe(
            map((value) => value.data),
            map((value: InitRes) => value)
          );
        } else {
          return of(null);
        }
      })
    );
  }

  getCachedCalendarById(calendarId) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => {
        if (token) {
          return this.cacheService.getCachedRequest(this.url)
            .pipe(
              map((value) => Object.values(value.data.calendars)),
              map((days: [CalendarRes[]]) => [].concat.apply([], days)),
              map((calendars: CalendarRes[]) => calendars.filter(calendar => calendar?.id === calendarId)),
              map((calendar: CalendarRes[]) => calendar[0])
            );
        } else {
          return of(null);
        }
      })
    );
  }

  postCalendarStats(calendarStats: CalendarStatsData, oldCalendar: CalendarRes) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const calendarStatsReq: CalendarStatsReq = {
                action: 'SET_PHK',
                token,
                data: calendarStats,
              };
              return this.http.post(this.url, calendarStatsReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(() => this.cacheService.updateCachedCalendarStats(this.url, calendarStats, oldCalendar,true)),
                );
            } else {
              return this.cacheService.updateCachedCalendarStats(this.url, calendarStats, oldCalendar,false);
            }
          })
        ))
    );
  }

  postCalendarState(calendarState: string, oldCalendar: CalendarRes) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const calendarStateReq: CalendarStateReq = {
                action: 'CALENDAR_STATE',
                token,
                data: {
                  id: oldCalendar.id,
                  state: calendarState
                },
              };
              return this.http.post<CalendarRes>(this.url, calendarStateReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(newCalendar => this.cacheService.updateCachedCalendarStatsState(this.url, newCalendar, oldCalendar, true)),
                );
            } else {
              oldCalendar.calendar_state_id = calendarState;
              return this.cacheService.updateCachedCalendar(this.url, oldCalendar, oldCalendar, false);
            }
          })
        ))
    );
  }

  postOrder(order: OrderRes, oldCalendar: CalendarRes) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              order.oldId = order.id;
              if (order.unsynced) {
                order.id = 0;
              }
              const orderReq: OrderReq = {
                action: 'ORDER',
                token,
                data: {
                  id: oldCalendar.id,
                  order,
                },
              };
              return this.http.post(this.url, orderReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap((result: OrderRes) => this.cacheService.updateCachedOrder(this.url, result, oldCalendar,true)
                    .pipe(
                      switchMap(() => of(result))
                    )),
                );
            } else {
              return this.cacheService.updateCachedOrder(this.url, order, oldCalendar,false)
                .pipe(
                  map(result => order)
                );
            }
          })
        ))
    );
  }

  postNewFile(file: File, documentId: number, documentType: string, contactId: number, calendar) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const formData: FormData = new FormData();
              formData.append('file', file, file.name);
              formData.append('fileName', file.name);
              formData.append('action', 'UPLOAD_FILE');
              formData.append('token', token);
              formData.append('data', JSON.stringify(
                {
                  id: documentId,
                  document: documentType,
                }));
              const headers = new HttpHeaders();
              headers.append('Content-Type', 'multipart/form-data');
              return this.http.post(this.url, formData, {headers})
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap((result: DocumentRes) => {
                    result.fileName = file.name;
                    result.name = file.name;
                    result.type = file.type;
                    switch (documentType) {
                      case 'orderin' :
                        return this.cacheService.updateCachedOrderDocument(this.url, documentId, contactId, result, 0,calendar,true);
                      case 'business_calendar' :
                        return this.cacheService.updateCachedCalendarDocument(this.url, result, calendar, true);
                      case 'contact' :
                        return this.cacheService.updateCachedContactDocument(this.url, contactId, result, calendar,true);
                    }
                  }),
                );
            } else {
              return this.toFilesBase64(file).pipe(
                switchMap((base64: string) => {
                const document: DocumentRes = {
                  id: Math.floor(Math.random() * 999999999999999999),
                  fileName: file.name,
                  name: file.name,
                  type: file.type,
                  base64: base64,
                };
                switch (documentType) {
                  case 'orderin' :
                    return this.cacheService.updateCachedOrderDocument(this.url, documentId, contactId, document, document.id,calendar,false);
                  case 'business_calendar' :
                    return this.cacheService.updateCachedCalendarDocument(this.url, document, calendar, false);
                  case 'contact' :
                    return this.cacheService.updateCachedContactDocument(this.url, contactId, document, calendar,false);
                }
              }
            ));
            }
          })
        ))
    );
  }

  postCalendarGift(calendarGifts: GiftRes[], oldCalendar: CalendarRes) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const calendarStatsReq: CalendarGiftsReq = {
                action: 'GIFTS',
                token,
                data: {
                  id: oldCalendar.id,
                  gifts: calendarGifts
                },
              };

              return this.http.post<GiftsRes>(this.url, calendarStatsReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(() => this.cacheService.updateCachedCalendarGifts(this.url, calendarGifts, oldCalendar,true)),
                );
            } else {
              return this.cacheService.updateCachedCalendarGifts(this.url, calendarGifts, oldCalendar, false);
            }
          })
        ))
    );
  }

  postContact(contact: ContactRes, oldCalendar: CalendarRes, recommendation: boolean = false) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const action = (recommendation ? 'RECOMMENDATION' : 'CONTACT');
              const contactReq: ContactReq = {
                action,
                token,
                data: {
                  id: oldCalendar.id,
                  contact
                },
              };
              return this.http.post<any>(this.url, contactReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(result => this.cacheService.updateCachedContact(this.url, result, oldCalendar, recommendation, true)),
                );
            } else {
              return this.cacheService.updateCachedContact(this.url, contact, oldCalendar, recommendation, false);
            }
          })
        ))
    );
  }

  deleteContact(contact: ContactRes, oldCalendar: CalendarRes, recommendation: boolean = false) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const action = (recommendation ? 'RECOMMENDATION_DELETE' : 'CONTACT_DELETE');
              const contactReq: ContactReq = {
                action,
                token,
                data: {
                  id: oldCalendar.id,
                  idContact: contact.id
                },
              };
              return this.http.post<any>(this.url, contactReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  tap(res => console.log(res)),
                  switchMap(() => this.cacheService.deleteCachedContact(this.url, contact, oldCalendar, recommendation)),
                );
            } else {
              return of(null);
            }
          })
        ))
    );
  }

  postCalendar(calendar: CalendarRes, oldCalendar: CalendarRes) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const contactReq: CalendarReq = {
                action: 'CALENDAR',
                token,
                data: {
                  calendar
                },
              };
              return this.http.post<any>(this.url, contactReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  switchMap(result => this.cacheService.updateCachedCalendar(this.url, result, oldCalendar, true)
                  ),
                );
            } else {
              return of(null);
            }
          })
        ))
    );
  }


  removeDMS(dmsFileId: number, oldCalendar: CalendarRes, documentId: number, document: string) {
    return this.loginService.getToken().pipe(
      catchError(err => throwError(this.errorHandler(err))),
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const action = ('DMS_DELETE');
              const dmsReq = {
                action,
                token,
                data: {
                  document,
                  id: documentId,
                  idDMS: dmsFileId
                },
              };
              return this.http.post<any>(this.url, dmsReq)
                .pipe(
                  catchError(err => throwError(this.errorHandler(err))),
                  tap(res => console.log(res)),
                  switchMap(() => this.cacheService.deleteCachedDMS(this.url, dmsFileId, oldCalendar,documentId, document)),
                );
            } else {
              return of(null);
            }
          })
        ))
    );
  }

  sendErrorByEmail(errorMessage) {
    return this.loginService.getToken().pipe(
      switchMap(token => this.networkStatus$
        .pipe(
          take(1),
          switchMap(connection => {
            if (connection.connected) {
              const errorReq: ErrorReq = {
                action: 'EXCEPTION',
                token,
                data: {
                  errorMessage
                },
              };
              return this.http.post<any>(this.url, errorReq);
              return of(null);
            } else {
              return of(null);
            }
          })
        ))
    );
  }


  toFilesBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  dataURItoBlob(dataURI, type) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: type });
    return blob;
  }

  errorHandler(err) {
    console.warn(err);
    this.sendErrorByEmail(JSON.stringify(err)).subscribe();
    if (Number(err.status) === 401) {
      this.loginService.deleteAuthData().subscribe();
    }
    if (Number(err.status) === 405) {
      this.router.navigate([`/home`], {replaceUrl: true});
    }
    return err;
  }
}
