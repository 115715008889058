import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {LoadingController, MenuController} from '@ionic/angular';
import {IonSlides} from '@ionic/angular';
import {BehaviorSubject, from, Observable, of, Subject, zip} from 'rxjs';
import {catchError, filter, map, shareReplay, switchMap, take, takeUntil} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {LoginService} from '../../services/login.service';
import {CalendarRes} from '../../models/calendar/calendarRes';
import {_Subject, InitRes} from '../../models/init/initRes';
import {GroupRes} from '../../models/group/groupRes';
import {UiHelperService} from '../../services/ui-helper.service';
import {LoginRes} from '../../models/login/loginRes';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})

export class HomePage implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  calendars = {};
  selectedCalendars: CalendarRes[];
  selectedIntrausers: any[];
  days: Date[];
  groups: GroupRes[];
  intrauser: LoginRes;
  subjects: _Subject[];
  isEditable$: Observable<any>;
  selectedSubjectId: string;
  initializationLoading: any;
  actDay: number = new Date(new Date().getFullYear(),new Date().getMonth(), 0).getDate() -1 + new Date().getDate();
  initReady = new BehaviorSubject(false);
  private unsubscribe = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private loadingCtrl: LoadingController,
    private datePipe: DatePipe,
    private loginService: LoginService,
    private uiHelper: UiHelperService,
    private menuCtrl: MenuController
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter(): void {
    this.menuCtrl.enable(true);
  }

  async ionViewWillEnter() {
    this.days = this.generateDays();
    this.selectedCalendars = [];
    const loadingCtrl = await this.loadingCtrl.create({
      message: 'Inicijaliziranje',
      spinner: 'bubbles'
    });
    await loadingCtrl.present();
    zip(
      this.apiService.getInitData(),
      this.loginService.getIntrauser(),
      this.loginService.getSelectedSubjectId(),
      )
      .pipe(take(1))
      .subscribe(async values => {
          console.log(values);
          this.calendars = values[0].calendars;
          this.groups = [...values[0].groups];
          this.subjects = values[0].subjects;
          this.intrauser = values[1];
          this.selectedSubjectId = values[2];
          this.subjects = this.subjects.filter(subject => Number(subject.id) !== Number(this.intrauser.intrauser_id));
          this.subjects.unshift({name: this.intrauser.intrauser_name, id: this.intrauser.intrauser_id});
          if (!this.selectedSubjectId) {
            this.selectedSubjectId = this.intrauser.intrauser_id.toString();
            this.loginService.setSelectedSubjectId(this.selectedSubjectId).subscribe(
              () => this.isEditable$ = this.loginService.isEditable().pipe(take(1))
          )
          }
          if (Number(this.selectedSubjectId) !== Number(this.intrauser.intrauser_id)) {
            await this.changeSelectedSubject();
          }
          this.initReady.next(true);
          this.slideChanged();
          loadingCtrl.dismiss();
          this.isEditable$ = this.loginService.isEditable().pipe(take(1));
        },
      error => {
        this.apiService.sendErrorByEmail(JSON.stringify(error)).subscribe();
        this.uiHelper.presentToast('Inicijalizacija aplikacije nije uspjela, pokušajte ponovno');
        console.error(error);
        loadingCtrl.dismiss();
      }, () => {
      }
    );
  }

  generateDays(): Date[] {
    const dayArr: Date[]=[];
    const actDay = new Date()
    const numberOfDays = new Date(actDay.getFullYear(),actDay.getMonth(), 0).getDate() -1;
    const firstDay=new Date(new Date().setDate(-numberOfDays));
    const lastDay=new Date(new Date().setDate(new Date().getDate() + 8));
    for (; firstDay<lastDay; firstDay.setDate(firstDay.getDate() + 1)){
      dayArr.push(new Date(firstDay));
    }
    return dayArr;
  };

  getDayBadge(day): string {
    if (this.calendars[day.toString()]) {
      return this.calendars[day.toString()].length.toString();
    }
    return '';
  }

  slideChanged(){
    if(this.days) {
      this.slides.getActiveIndex().then(
        index => {
          this.selectedCalendars = [];
          this.getSelectedShowCases(this.days[index])
            .pipe(
              take(1)
            ).subscribe(
            (calendars: CalendarRes[]) => {
              this.selectedCalendars = calendars;
              this.getIntrausersFromCalendars();
            }
          );
          this.getIntrausersFromCalendars();
        }
      );
    }
  }

  getSelectedShowCases(selectedDay: Date) {
    const keyDate = this.datePipe.transform(selectedDay,'dd.MM.YYYY');
    return this.initReady.pipe(
      take(1),
      takeUntil(this.unsubscribe),
      filter(ready => ready),
      switchMap(() =>
        of(this.calendars).pipe(
        takeUntil(this.unsubscribe),
        filter(val => val[keyDate]),
        map((result: CalendarRes[]) => result[keyDate],
        ))
      ));
  }

  getStateColor(state: string): string{
    return this.uiHelper.getStateColor(state);
  }

  ionViewWillLeave() {
    this.initReady.next(false);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async changeSelectedSubject() {
    const loadingCtrl = await this.loadingCtrl.create({
      message: 'Inicijaliziranje',
      spinner: 'bubbles'
    });
    loadingCtrl.present();
    return zip(
      this.loginService.setSelectedSubjectId(this.selectedSubjectId),
      this.apiService.getSubjectData(this.selectedSubjectId)
    ).pipe(take(1))
      .subscribe(
        () => {
          return this.apiService.getCachedInitData()
            .pipe(take(1))
            .subscribe(initData => {
            this.calendars = initData.calendars;
            console.log(initData);
            this.slideChanged();
            loadingCtrl.dismiss();
            this.isEditable$ = this.loginService.isEditable().pipe(take(1));
            });
        },
        error => {
          this.apiService.sendErrorByEmail(JSON.stringify(error)).subscribe();
          console.warn(error);
          this.uiHelper.presentToast('Promjena korisnika nije uspjela');
          loadingCtrl.dismiss();
        }
      );
  }

  getIntrausersFromCalendars(){
    this.selectedIntrausers =[];
    this.selectedCalendars.forEach(calendar => {
      const selectedIntrauser = {id:calendar.intrauser_id,intrauserName: calendar.intrauser_name};
      if (!this.selectedIntrausers.find(intrauser => intrauser.id === selectedIntrauser.id)){
        this.selectedIntrausers.push(selectedIntrauser);
      }
    })
  }

  doRefresh($event: any) {
    this.changeSelectedSubject().then(
      res=> {
        $event.target.complete();
      }
    )
  }
}
