import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CalendarRes, CalendarStateReq, DocumentRes } from '../models/calendar/calendarRes';
import { CalendarStatsData, CalendarStatsReq } from '../models/calendarStats/calendarStatsReq';
import { OrderRes } from '../models/order/orderRes';
import { InitRes } from '../models/init/initRes';
import { Network } from '@capacitor/network';
import { GiftsData } from '../models/gift/giftReq';
import { GiftRes, GiftsRes } from '../models/gift/giftRes';
import { ContactRes } from '../models/contact/contactRes';
import { CalendarStatsRes } from '../models/calendar/calendarStatsRes';

const TTL = 25;
const CACHE_KEY = '_cached_';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private storageReady = new BehaviorSubject(false);

  constructor(private storage: Storage) {
  }

  async initStorage(): Promise<void> {
    await this.storage.defineDriver(CordovaSQLiteDriver);
    await this.storage.create();
    this.storageReady.next(true);
  }

  cacheRequests(url, data, timeToLive?): Observable<any> {
    let validUntil;
    if (timeToLive === null) {
      validUntil = (new Date().getTime()) + TTL * 1000;
    } else {
      validUntil = (new Date().getTime()) + timeToLive * 1000;
    }
    url = `${CACHE_KEY}${url}`;

    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.set(url, { validUntil, data }))
      ));
  }

  getCachedRequest(url): Observable<any> {
    const currentTime = new Date().getTime();
    url = `${CACHE_KEY}${url}`;

    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else if (result.validUntil < currentTime) {
          this.storage.remove(url);
          return of(null);
        } else {
          return of(result);
        }
      })
    );
  }

  async clearCacheData(): Promise<void> {
    const keys = await this.storage.keys();
    keys.map(async key => {
      if (key.startsWith(CACHE_KEY)) {
        this.storageReady.pipe(
          filter(ready => ready),
          switchMap(_ => from(this.storage.remove(key)))
        ).subscribe();
      }
    });
  }

  invalidateCacheEntry(url): Observable<any> {
    url = `${CACHE_KEY}${url}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.remove(url)))
    );
  }

  cacheSubjectData(url2, subjectData): Observable<any> {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      map(result => result.data),
      switchMap((result: InitRes) => {
        if (!result) {
          return of(null);
        } else {
          result.calendars = subjectData.calendars;
          result.orders = subjectData.orders;
          return this.cacheRequests(url2, result, 2 * 60 * 60);
        }
      })
    );
  }

  updateCachedCalendarStats(url2, newCalendarStat: CalendarStatsData, calendar: CalendarRes, connected: boolean) {
    const url = `${CACHE_KEY}${url2}`;
    calendar.calendar_contacts = newCalendarStat.calendar_contacts.toString();
    calendar.calendar_pairs = newCalendarStat.calendar_pairs.toString();
    calendar.calendar_sms = newCalendarStat.calendar_sms.toString();
    calendar.calendar_guests = newCalendarStat.calendar_guests.toString();

    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(oldCalendar => {
            if (Number(oldCalendar?.id) === Number(calendar?.id)) {
              oldCalendar = calendar;
              if (!connected) {
                oldCalendar.unsynced = true;
              }
            }
            return oldCalendar;
          });
          console.log(result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateCachedCalendarStatsState(url2, newCalendarStat: CalendarStatsRes, calendar: CalendarRes, connected: boolean) {
    const url = `${CACHE_KEY}${url2}`;
    calendar.calendar_contacts = newCalendarStat.calendar_contacts.toString();
    calendar.calendar_pairs = newCalendarStat.calendar_pairs.toString();
    calendar.calendar_sms = newCalendarStat.calendar_sms.toString();
    calendar.calendar_guests = newCalendarStat.calendar_guests.toString();
    calendar.calendar_state = newCalendarStat.calendar_state.toString();
    calendar.calendar_state_id = newCalendarStat.calendar_state_id.toString();

    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(oldCalendar => {
            if (Number(oldCalendar?.id) === Number(calendar?.id)) {
              oldCalendar = calendar;
              if (!connected) {
                oldCalendar.unsynced = true;
              }
            }
            return oldCalendar;
          });
          console.log(result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateCachedCalendarGifts(url2, newCalendarGifts, calendar: CalendarRes, connected) {
    const url = `${CACHE_KEY}${url2}`;

    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(oldCalendar => {
            if (Number(oldCalendar?.id) === Number(calendar?.id)) {
              oldCalendar.gifts = newCalendarGifts;
              if (!connected) {
                oldCalendar.unsynced = true;
              }
            }
            return oldCalendar;
          });
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateCachedContact(url2, newContact, calendar: CalendarRes, recommendation: boolean = false, connected: boolean) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          if (!connected) {
            newContact.unsynced = true;
            console.log('id: ', newContact.id);
            if (newContact.id === 0 || newContact.id === null) {
              newContact.id = Number(newContact.mobile.replace('+', ''));
            }
            if (!newContact.documents) {
              newContact.documents = [];
            }
          }
          console.log('newContact ', newContact);
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map((oldCalendar: CalendarRes) => {
            if (Number(oldCalendar?.id) === Number(calendar?.id)) {
              if (!recommendation) {
                const contactOld = oldCalendar.contacts.find(contactA => Number(contactA.id) === Number(newContact.id));
                if (!contactOld) {
                  oldCalendar.contacts.push(newContact);
                } else {
                  const foundIndex = oldCalendar.contacts.findIndex(x => x.id === contactOld.id);
                  oldCalendar.contacts[foundIndex] = newContact;
                }
              } else {
                const recommendationOld = oldCalendar.recommendations.find(
                  recommendationA => Number(recommendationA.id) === Number(newContact.id
                  ));
                if (!recommendationOld) {
                  oldCalendar.recommendations.push(newContact);
                } else {
                  const foundIndex = oldCalendar.recommendations.findIndex(x => x.id === recommendationOld.id);
                  oldCalendar.recommendations[foundIndex] = newContact;
                }
              }
              // if (!connected) {
              //   oldCalendar.unsynced = true;
              // }
              return oldCalendar;
            }
          });
          console.log('New calendar', result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60).pipe(
            switchMap(newCalendar => of(newContact))
          );
        }
      })
    );
  }

  deleteCachedContact(url2, deletedContact, calendar: CalendarRes, recommendation: boolean = false) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(calendarOld => {
            if (Number(calendarOld?.id) === Number(calendar?.id)) {
              if (!recommendation) {
                const contactOld = calendarOld.contacts.find(contactA => Number(contactA.id) === Number(deletedContact.id));
                if (contactOld) {
                  const foundIndex = calendarOld.contacts.findIndex(x => x.id === contactOld.id);
                  calendarOld.contacts.splice(foundIndex, 1);
                }
              } else {
                const recommendationOld = calendarOld.recommendations.find(
                  recommendationA => Number(recommendationA.id) === Number(deletedContact.id
                  ));
                if (recommendationOld) {
                  const foundIndex = calendarOld.recommendations.findIndex(x => x.id === recommendationOld.id);
                  calendarOld.recommendations.splice(foundIndex, 1);
                }
              }
              return calendarOld;

            }
          });
          console.log('New calendar', result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateCachedOrder(url2, newOrder: OrderRes, calendar: CalendarRes, connected: boolean) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {

          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map((calendarOld: CalendarRes) => {
            if (Number(calendarOld?.id) === Number(calendar?.id)) {
              const contact = calendarOld.contacts.find(contactA => Number(contactA.id) === Number(newOrder.contact_id));

              if (!contact.orders) {
                contact.orders = [];
              }

              if (!connected) {
                newOrder.unsynced = true;
                newOrder.id = Number(contact.id.toString() + (contact.orders.length + 1).toString());
                newOrder.number = newOrder.id.toString();
              }

              const oldOrder = contact.orders.find(order => Number(order.id) === Number(newOrder.id));

              if (!oldOrder) {
                contact.orders.push(newOrder);
              } else {
                const foundIndex = contact.orders.findIndex(x => Number(x.id) === Number(newOrder.id));
                contact.orders[foundIndex] = newOrder;
              }
              const foundIndexContact = calendarOld.contacts.findIndex(x => Number(x.id) === Number(contact.id));
              calendarOld.contacts[foundIndexContact] = contact;


              const oldOrderData = result.data.orders.find(order => Number(order.id) === Number(newOrder.id));
              if (oldOrderData) {
                const foundIndex = result.data.orders.findIndex(x => Number(x.id) === Number(newOrder.id));
                result.data.orders[foundIndex] = newOrder;
              } else {
                result.data.orders.push(newOrder);
              }
              return calendarOld;
            }
          });
          console.log('New calendar', result.data);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateCachedOrderDocument(url2, orderId: number, contactId: number, newDocument: DocumentRes, oldDocumentId: number, calendar: CalendarRes, connected) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {

        if (!result) {
          return of(null);
        } else {
          if (!connected) {
            newDocument.unsynced = true;
          }

          if (oldDocumentId !== 0) {

          }

          const oldOrderData = result.data.orders.find(order => Number(order.id) === Number(orderId));
          if (oldOrderData) {
            const foundIndex = result.data.orders.findIndex(x => Number(x.id) === Number(orderId));
            if (result.data.orders[foundIndex]?.documents.length === 0) {
              result.data.orders[foundIndex].documents = [];
            }
            if (result.data.orders[foundIndex].documents.find(document => document.id === oldDocumentId)) {
              result.data.orders[foundIndex].documents.map(documentX => {
                if (documentX.id === oldDocumentId) {
                  documentX = newDocument;
                }
                return documentX;
              });
            } else {
              result.data.orders[foundIndex].documents.push(newDocument);
            }
          }

          // result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(calendarOld => {
          //   if (Number(calendarOld?.id) === Number(calendar?.id)) {
          //     const contact = calendarOld.contacts.find(contactA => Number(contactA.id) === Number(contactId));
          //
          //     if (!contact.orders) {
          //       contact.orders = [];
          //     }
          //
          //     const foundIndex = contact.orders.findIndex(x => Number(x.id) === Number(orderId));
          //     if (!contact.orders[foundIndex].documents) {
          //       contact.orders[foundIndex].documents = [];
          //     }
          //
          //     if (contact.orders[foundIndex].documents.find(document => document.id === oldDocumentId)) {
          //       contact.orders[foundIndex].documents.map(documentX => {
          //         if (documentX.id === oldDocumentId) {
          //           documentX = newDocument;
          //         }
          //         return documentX;
          //       });
          //     } else {
          //       contact.orders[foundIndex].documents.push(newDocument);
          //     }
          //     const foundIndexContact = calendarOld.contacts.findIndex(x => Number(x.id) === Number(contact.id));
          //     calendarOld.contacts[foundIndexContact] = contact;
          //     console.log('New calendar', result.data);
          //
          //     return calendarOld;
          //   }
          // });
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  // updateCachedOrder(url2, newOrder: OrderRes, calendar: CalendarRes, connected: boolean) {
  //   const url = `${CACHE_KEY}${url2}`;
  //   return this.storageReady.pipe(
  //     filter(ready => ready),
  //     switchMap(_ => from(this.storage.get(url))),
  //     switchMap(result => {
  //       if (!result) {
  //         return of(null);
  //       } else {
  //
  //         result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(calendarOld => {
  //           if (Number(calendarOld?.id) === Number(calendar?.id)) {
  //             const contact = calendarOld.contacts.find(contactA => Number(contactA.id) === Number(newOrder.contact_id));
  //
  //             if (!contact.orders) {
  //               contact.orders = [];
  //             }
  //
  //             if (!connected) {
  //               newOrder.unsynced = true;
  //               newOrder.id = Number(contact.id.toString() + (contact.orders.length + 1).toString());
  //               newOrder.number = newOrder.id.toString();
  //             }
  //
  //             const oldOrder = contact.orders.find(order => Number(order.id) === Number(newOrder.id));
  //
  //             if (!oldOrder) {
  //               contact.orders.push(newOrder);
  //             } else {
  //               const foundIndex = contact.orders.findIndex(x => Number(x.id) === Number(newOrder.id));
  //               contact.orders[foundIndex] = newOrder;
  //             }
  //             const foundIndexContact = calendarOld.contacts.findIndex(x => Number(x.id) === Number(contact.id));
  //             calendarOld.contacts[foundIndexContact] = contact;
  //
  //
  //             const oldOrderData = result.data.orders.find(order => Number(order.id) === Number(newOrder.id));
  //             if (oldOrderData) {
  //               const foundIndex = result.data.orders.findIndex(x => Number(x.id) === Number(newOrder.id));
  //               result.data.orders[foundIndex] = newOrder;
  //             } else {
  //               result.data.orders.push(newOrder);
  //             }
  //
  //             return calendarOld;
  //           }
  //         });
  //         console.log('New calendar', result.data);
  //         return this.cacheRequests(url2, result.data, 2 * 60 * 60);
  //       }
  //     })
  //   );
  // }

  updateCachedCalendarDocument(url2, document: DocumentRes, calendar: CalendarRes, connected: boolean) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          if (!connected) {
            document.unsynced = true;
          }
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(calendarA => {
            if (Number(calendarA?.id) === Number(calendar?.id)) {
              if (!connected) {
                document.id = Number(calendar.id.toString() + (calendar.documents.length + 1).toString());
                document.unsynced = true;
              }
              calendarA.documents.push(document);
              return calendarA;
            }
          });
          console.log('New calendar', result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateCachedContactDocument(url2, contactId: number, document: DocumentRes, calendar: CalendarRes, connected: boolean) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          if (!connected) {
            document.unsynced = true;
          }
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(oldCalendar => {
            if (Number(oldCalendar?.id) === Number(calendar?.id)) {
              const contactOld = oldCalendar.contacts.find(contactA => Number(contactA.id) === Number(contactId));
              if (contactOld) {
                const foundIndex = oldCalendar.contacts.findIndex(x => x.id === contactOld.id);
                oldCalendar.contacts[foundIndex].documents.push(document);
              }
              return oldCalendar;
            }
          });
          console.log('New calendar', result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateCachedCalendar(url2: string, newCalendar: CalendarRes, oldCalendar: CalendarRes, connected: boolean) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          if (!result.data.calendars[newCalendar.date]) {
            result.data.calendars[newCalendar.date] = [];
          }
          if (!connected) {
            newCalendar.unsynced = true;
          }
          result.data.calendars[newCalendar.date].push(newCalendar);
          console.log('New calendar', result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  cacheZarenClubCard(url2: string, cardNumber: string, contactId: number, calendar: CalendarRes) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => from(Network.getStatus())
        .pipe(
          switchMap(connection => {
            if (!result) {
              return of(null);
            } else {
              result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(oldCalendar => {
                  if (Number(oldCalendar?.id) === Number(calendar?.id)) {
                    const contactOld = oldCalendar.contacts.find(contactA => Number(contactA.id) === Number(contactId));
                    const foundIndex = oldCalendar.contacts.findIndex(x => x.id === contactOld.id);
                    oldCalendar.contacts[foundIndex].card2 = cardNumber;
                  }
                  return oldCalendar;
                }
              );
              return this.cacheRequests(url2, result.data, 2 * 60 * 60).pipe(
                switchMap(newCalendar => of(cardNumber))
              );
            }
          })
        ))
    );
  }


  updateUnsyncedCalendarGifts(url2, newGifts: GiftRes[], calendar: CalendarRes) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(cachedCalendar => {
            if (Number(cachedCalendar?.id) === Number(calendar?.id)) {
              cachedCalendar.gifts = newGifts;
            }
            return cachedCalendar;
          });
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateUnsyncedCalendarStats(url2, calendar: CalendarRes) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(cachedCalendar => {
            if (Number(cachedCalendar?.id) === Number(calendar?.id)) {
              cachedCalendar.calendar_contacts = calendar.calendar_contacts.toString();
              cachedCalendar.calendar_pairs = calendar.calendar_pairs.toString();
              cachedCalendar.calendar_sms = calendar.calendar_sms.toString();
              cachedCalendar.calendar_guests = calendar.calendar_guests.toString();
            }
            return cachedCalendar;
          });
          console.log(result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  changeSyncStatusCalendar(url2, calendar: CalendarRes) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(cachedCalendar => {
            if (Number(cachedCalendar?.id) === Number(calendar?.id)) {
              cachedCalendar.unsynced = false;
            }
            return cachedCalendar;
          });
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  changeSyncStateCalendar(url2, calendar: CalendarRes) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map((cachedCalendar: CalendarRes) => {
            if (Number(cachedCalendar?.id) === Number(calendar?.id)) {
              cachedCalendar.calendar_state_id = calendar.calendar_state_id;
              cachedCalendar.calendar_state = calendar.calendar_state;
            }
            return cachedCalendar;
          });
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  updateUnsyncedContact(url2, newContact: ContactRes, oldContact: ContactRes, calendar: CalendarRes, recommendation: boolean = false) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {
          console.log('newContact ', newContact);
          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map((oldCalendar: CalendarRes) => {
            if (Number(oldCalendar?.id) === Number(calendar?.id)) {
              newContact.documents = oldContact.documents;
              newContact.orders = oldContact.orders;
              newContact.orders.forEach(order => order.contact_id = newContact.id);

              if (!recommendation) {
                const foundIndex = oldCalendar.contacts.findIndex(x => Number(x.id) === Number(oldContact.oldId));
                const recommendations = oldCalendar.recommendations;
                recommendations.forEach(recommendation => {
                  if (recommendation.recommender_id === oldContact.oldId) {
                    recommendation.recommender_id = newContact.id;
                  }
                });
                const orders = oldCalendar.orders;
                if (orders) {
                  orders.forEach(order => {
                    if (order.contact_id === oldContact.oldId) {
                      order.contact_id = newContact.id;
                    }
                  });
                }
                oldCalendar.recommendations = recommendations;
                oldCalendar.orders = orders;
                oldCalendar.contacts[foundIndex] = newContact;
              } else {
                const foundIndex = oldCalendar.recommendations.findIndex(x => Number(x.id) === Number(oldContact.oldId));
                oldCalendar.recommendations[foundIndex] = newContact;
              }
              return oldCalendar;
            }
          });
          console.log('New calendar', result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60).pipe(
            switchMap(newCalendar => of(newContact))
          );
        }
      })
    );
  }

  updateUnsyncedOrder(url2, newOrder: OrderRes, oldOrder: OrderRes, calendar: CalendarRes) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {

          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map(calendarOld => {
            if (Number(calendarOld?.id) === Number(calendar?.id)) {
              const contact = calendarOld.contacts.find(contactA => Number(contactA.id) === Number(newOrder.contact_id));

              if (!contact.orders) {
                contact.orders = [];
              }

              if (!oldOrder) {
                contact.orders.push(newOrder);
              } else {
                newOrder.documents = oldOrder.documents;
                const foundIndex = contact.orders.findIndex(x => Number(x.id) === Number(oldOrder.oldId));
                contact.orders[foundIndex] = newOrder;
              }
              const foundIndexContact = calendarOld.contacts.findIndex(x => Number(x.id) === Number(contact.id));
              calendarOld.contacts[foundIndexContact] = contact;


              const oldOrderData = result.data.orders.find(order => Number(order.id) === Number(oldOrder.oldId));
              if (oldOrderData) {
                const foundIndex = result.data.orders.findIndex(x => Number(x.id) === Number(oldOrder.oldId));
                result.data.orders[foundIndex] = newOrder;
              } else {
                result.data.orders.push(newOrder);
              }

              return calendarOld;
            }
          });
          console.log('New calendar', result.data);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }

  deleteCachedDMS(url2: string, dmsFileId: number, calendar: CalendarRes, documentId: number, document: string) {
    const url = `${CACHE_KEY}${url2}`;
    return this.storageReady.pipe(
      filter(ready => ready),
      switchMap(_ => from(this.storage.get(url))),
      switchMap(result => {
        if (!result) {
          return of(null);
        } else {

          if(document === 'orderin') {
            const orderIndex = result.data.orders.findIndex(order => Number(order.id) === Number(documentId));
            const dmsIndex = result.data.orders[orderIndex].documents.findIndex(dms => Number(dms.id) === Number(dmsFileId));
            result.data.orders[orderIndex].documents.splice(dmsIndex, 1);
          }

          result.data.calendars[calendar.date] = result.data.calendars[calendar.date].map((calendarOld: CalendarRes) => {
            if (Number(calendarOld?.id) === Number(calendar?.id)) {

              if (document === 'orderin') {
                const foundContactIndex = calendarOld.contacts.findIndex(contact => {
                  if (contact.orders.find(contactOrder => Number(contactOrder.id) === Number(documentId))) {
                    return contact;
                  }
                });

                const foundOrder = calendarOld.contacts[foundContactIndex].orders.find(order => Number(order.id) === Number(documentId));
                if (foundOrder) {
                  const foundIndexOrder = calendarOld.contacts[foundContactIndex].orders
                    .findIndex(x => Number(x.id) === Number(foundOrder.id));
                  const foundIndexDMS = calendarOld.contacts[foundContactIndex].orders[foundIndexOrder]
                    .documents?.findIndex(dms => Number(dms.id) === Number(dmsFileId));
                  if(foundIndexDMS >= 0) {
                    calendarOld.orders[foundIndexOrder].documents.splice(foundIndexDMS, 1);
                  }
                }
              } else if (document === 'business_calendar') {
                const foundIndexDMS = calendarOld.documents.findIndex(dms => Number(dms.id) === Number(dmsFileId));
                if (foundIndexDMS >= 0) {
                  calendarOld.documents.splice(foundIndexDMS, 1);
                }
              }
              return calendarOld;
            }
          });
          console.log('New calendar', result);
          return this.cacheRequests(url2, result.data, 2 * 60 * 60);
        }
      })
    );
  }
}
