import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];
  constructor(private router: Router, private location: Location, private platform: Platform) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.back();
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/home');
    }
  }
}
