import {Injectable} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {Network} from '@capacitor/network';
import {catchError, switchMap, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';
import {UiHelperService} from './ui-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SelfUpdateService {
  axios = require('axios').default;
  newestVersionUrl = `${environment.apkUrl}/assets/version.txt`;
  updateUrl = `${environment.apkUrl}/api/zrn/zarenak/zarenak.apk`;
  downloadProgress = 0;
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private fileOpener: FileOpener,
    private uiHelper: UiHelperService
  ) { }

  getNewestVersion(): Observable<any> {
    return from(Network.getStatus())
      .pipe(
        take(1),
        switchMap(connection => {
          if (connection.connected) {
            return this.http.get<any>(this.newestVersionUrl).pipe(
              catchError(err => throwError(this.apiService.errorHandler(err))),
            );
          } else {
            return of(null);
          }
        })
      );
  }

  async getNewestUpdate() {
    this.axios({
      url: this.updateUrl,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: progressEvent => {
        this.downloadProgress = Math.floor(progressEvent.loaded / 39042481 * 100);
      }
    }).then((response) => {
      console.log(response);
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      const fileOpener = this.fileOpener;
      const uiHelperService = this.uiHelper;
            reader.addEventListener('load', function() {
              const base64data = reader.result;
              Filesystem.writeFile({path: `update.apk`, data: base64data.toString(), directory: Directory.Documents}).then(res=>{
                fileOpener.open(res.uri, 'application/vnd.android.package-archive')
                  .then(() => {
                  })
                  .catch(e => uiHelperService.presentToast('Nije uspjelo preuzimanje ažuriranja:' + JSON.stringify(e)));
              });

            }, false);
    });
  }
}
