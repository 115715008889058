import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../services/login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize, switchMap, take} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {MenuController, Platform} from '@ionic/angular';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import {App} from '@capacitor/app';
import {SyncService} from '../../services/sync.service';
import {ApiService} from '../../services/api.service';
import packageJson from '../../../../package.json';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  version$: Observable<any>;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private menuCtrl: MenuController,
    public platform: Platform,
    private syncService: SyncService,
    private appVersion: AppVersion,
    private apiService: ApiService
  ) { }
  ngOnInit() {
    if (this.platform.is('capacitor')) {
      App.addListener('appStateChange', ({isActive}) => {
        if (isActive) {
          this.version$ =from(this.appVersion.getVersionNumber()).pipe(take(1));
        }
      });
    }
  }

  changeNetworkConnection(event){
    this.syncService.networkStatus$.next({connected:  event.detail.checked, connectionType: 'wifi'});
    this.apiService.networkStatus$.next({connected:  event.detail.checked, connectionType: 'wifi'});

  }

  _navigateTo(url){
    this.menuCtrl.close().then(()=> {
      this.router.navigate([url]);
    }
  );
  }

  logout() {
    this.menuCtrl.close().then(()=> {
      this.loginService.deleteAuthData().subscribe(
        res => {
          // eslint-disable-next-line no-underscore-dangle
          this._navigateTo('/login');
        }
      );
      }
    );

  }

  navigateToPageInDev() {
    this.router.navigate(['event/424370/all-companies-form']
      , {
        queryParams:{
          contactId: 1395661,
          orderId: 213959,
          calendarId: 424370
        },
      });
  }

  reloadApp() {
    // @ts-ignore
    window.location.reload(true);
  }

  getVersion() {
    return packageJson.version;
  }
}
