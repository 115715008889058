 <ion-header>
    <ion-toolbar>
      <ion-buttons>
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <ion-title class="ion-text-center">
        <ion-row class="ion-justify-content-center">
          <ion-col size="auto">
            <ion-select okText="Izabrati" cancelText="Otkazati" [(ngModel)]="selectedSubjectId" (ionChange)="changeSelectedSubject()" *ngIf="initReady | async">
              <ion-select-option value="0" i18n>Všetci v mojej skupine</ion-select-option>
              <ion-select-option *ngFor="let subject of subjects" value="{{subject.id}}">{{subject.name}}</ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
      </ion-title>
      <ion-grid>
<!--        <ion-row>
          <ion-col class="ion-text-center" size-xs="4">
            Vývozy: <b>1 452€</b>
          </ion-col>
          <ion-col class="ion-text-center" size-xs="4">
            Ukážky: <b>15/18</b>
          </ion-col>
          <ion-col class="ion-text-center" size-xs="4">
            <ng-container *ngIf="(syncService.networkStatus$ | async) as status">
            <ion-badge [color]="status.connected ? 'success' : 'danger'">{{(status.connected? 'ONLINE' : 'OFFLINE')}}</ion-badge>
            </ng-container>
          </ion-col>
        </ion-row>-->
        <ion-row>
          <ion-col>
              <div class="ion-text-center">
                <ion-icon color="success" name="caret-down-outline"></ion-icon>
              </div>
              <ion-slides [options]="{ slidesPerView: 'auto', loop: false,initialSlide:this.actDay,centeredSlides:true,slideToClickedSlide:true}" (ionSlideDidChange)="slideChanged()">
                  <ion-slide *ngFor="let day of days">
                  <div class="calendar-slide">
                    <div>{{day | date:'d.M'}}</div>
                    <small>{{day | date:'EEEE' :''}}</small>
                    <ion-badge color="success">{{getDayBadge(day | date:'dd.MM.YYYY')}}</ion-badge>
                  </div>
                  </ion-slide>
              </ion-slides>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>

  <ion-content id="main-content">
    <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
      <ion-list>
        <ng-container *ngFor="let intrauser of selectedIntrausers">

          <div class="heading intrauserCard" *ngIf="selectedSubjectId === '0'">
            <h4>{{intrauser?.intrauserName}}</h4>
          </div>

          <ng-container *ngFor="let calendar of selectedCalendars">
            <ion-card *ngIf="(calendar.intrauser_id | number) === (intrauser.id | number)" [routerLink]="['/event',calendar.id,'detail']" class="offcolor-{{calendar.calendar_type_color}}">
              <ion-card-header>
                <ion-card-subtitle>{{calendar.calendar_type}}</ion-card-subtitle>
                <ion-card-title>{{calendar.name}} (ID: {{calendar.id}})</ion-card-title>
                <ion-card-subtitle class="event-time ion-text-end"><b>{{calendar.time}}</b><br/><small>{{calendar.date}}</small></ion-card-subtitle>
              </ion-card-header>
              <ion-card-content>
                <div>{{calendar.description}}</div>
                <div *ngIf="calendar.main_contact">
                  <strong><span i18n>Kontakt</span>:</strong> {{calendar.main_contact?.name}}, {{calendar.main_contact?.mobile}},{{!!calendar.main_contact?.phone && calendar.main_contact?.phone.length > 5 ? calendar.main_contact?.phone + ', ': ''}}{{calendar.main_contact?.street}}, {{calendar.main_contact?.city}}, {{calendar.main_contact?.zip}}
                </div>
                <div class="ion-text-end">
                  <ion-badge color="{{getStateColor(calendar.calendar_state_id.toString())}}">{{calendar.calendar_state}}</ion-badge>
                </div>
              </ion-card-content>
            </ion-card>
          </ng-container>
        </ng-container>

        <div class="any-showcase-div" *ngIf="selectedCalendars?.length <= 0">
          <ion-label class="ion-text-center any-showcase text" color="success">
            <h1 i18n>Na tento deň nemáte žiadne ukážky</h1>
          </ion-label>
        </div>
      </ion-list>


    <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="isEditable$ | async">
      <ion-fab-button [routerLink]="['/calendar-edit']">
        <ion-icon name="add-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-content>
