<ion-content>
    <div class="menu-logo">
      <ion-img src="assets/images/rispetto_logo.png"></ion-img>
    </div>
    <ion-list>
      <ion-item (click)="_navigateTo('/home')">
        <ion-icon name="calendar-number-outline"></ion-icon>
        <ion-label i18n>Kalendár</ion-label>
      </ion-item>
      <ion-item (click)="_navigateTo('/statistics')">
        <ion-icon name="stats-chart-outline"></ion-icon>
        <ion-label i18n>Štatistika</ion-label>
      </ion-item>
      <ion-item (click)="_navigateTo('/warehouse')">
        <ion-icon name="cube-outline"></ion-icon>
        <ion-label i18n>Stav skladu</ion-label>
      </ion-item>
      <ion-item (click)="_navigateTo('/exports')">
        <ion-icon name="cash-outline"></ion-icon>
        <ion-label i18n>Vývozy</ion-label>
      </ion-item>
    </ion-list>
    <div class="menu-footer">

      <ion-list>
        <ion-item (click)="reloadApp()">
          <ion-icon name="repeat-outline"></ion-icon>
          <ion-label i18n>Restart App</ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item (click)="logout()">
          <ion-icon name="log-out-outline"></ion-icon>
          <ion-label i18n>Odhlásiť sa</ion-label>
        </ion-item>
      </ion-list>
      <p class="ion-text-center"><small>Copyright © TABI Corp., s.r.o.</small></p>
      <p class="ion-text-center"><small><span i18n>Verzia</span>: {{getVersion()}}</small></p>
    </div>
  </ion-content>


<!--<ion-content>
  <ion-header>
    <ion-toolbar>
    </ion-toolbar>
  </ion-header>
  <ion-list>
    <ion-menu-toggle auto-hide="false">
      <ion-item>Menu Item</ion-item>
    </ion-menu-toggle>
  </ion-list>
</ion-content>-->
