import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule} from '@angular/common/http';
import { IonicStorageModule} from '@ionic/storage-angular';
import * as CordovaSQliteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from '@ionic/storage';
import {FormsModule} from '@angular/forms';
import {HomePageModule} from './pages/home/home.module';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot({
            // eslint-disable-next-line no-underscore-dangle
            driverOrder: [CordovaSQliteDriver._driver, Drivers.IndexedDB]
        }),
        FormsModule,
        HomePageModule
    ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, FileOpener, AppVersion],
  bootstrap: [AppComponent],
})
export class AppModule {}
