<ion-app>
  <ion-split-pane contentId="menu-content">
    <ion-menu contentId="menu-content" side="start">
      <ion-content>
        <app-menu></app-menu>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="menu-content" [animated]="false"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
