import {Injectable} from '@angular/core';
import {PushNotifications} from '@capacitor/push-notifications';
import {LocalNotifications} from '@capacitor/local-notifications';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(private router: Router,
  ) {
  }

  async addListeners() {
    await PushNotifications.addListener('registration', token => {
      localStorage.setItem('notificationToken', token.value);
    });

    await PushNotifications.addListener('registrationError', err => {
      localStorage.setItem('notificationToken', err.error);
    });
    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      this.router.navigate(['/event/'+ notification.notification.data.id + '/detail/' ]);
    });
    await LocalNotifications.addListener('localNotificationActionPerformed', notification => {
      this.router.navigate(['/event/'+ notification.notification.actionTypeId + '/detail/']);
    });
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      LocalNotifications.schedule({
        notifications: [{
          title: 'Local:' + notification.title,
          body: notification.body,
          id: Date.now(),
          actionTypeId: notification.data.id
        }]
      });
    });
  }

  async registerNotifications() {

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }
    await PushNotifications.register();
  }
}
