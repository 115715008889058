import { Injectable } from '@angular/core';
import {ToastController} from '@ionic/angular';
import {Tag} from '../models/contact/contactRes';

@Injectable({
  providedIn: 'root'
})
export class UiHelperService {

  constructor(
    private toastController: ToastController
  ) { }
  getStateColor(state: string): string{
    switch(state) {
      case '19333' : //'S060 - Uskutočnenie ukážky'
      case '19334' : //'S070 - Ukážka úspešná'
        return 'success';

      case '19358' : //'S050 - Pridelenie termínu'
      case '19388' : //'S055 - Potvrdená obchodníkom'
      case '19357' : //'S040 - Overenie OC'
        return 'warning';

      case '19354' : ///'S120 - Presun CC'
      case '19347' : //'S150 - Presun OZ'
      case '19389' : //'S999 - Storno CC'
      case '19341' : //'S999 - Storno OZ'
        return 'danger';

      default:
        return 'danger';
    }
  }


  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 10000
    });
    await toast.present();
  }

  enhanceTags(allTags: Tag[], contactTags: number[] | Tag[]) {
    if (contactTags){
      return contactTags.map(contactTag =>
        allTags.find(tag => Number(tag.id) === Number(contactTag))
      );
    }
  }

  normalizeString(str: string){
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^A-Z0-9]+/ig, '_');
  }

  cloneObject(obj) {
    if (obj === null || typeof (obj) !== 'object' || 'isActiveClone' in obj) {
      return obj;
    }
    let temp;
    if (obj instanceof Date)
      {
        // @ts-ignore
        temp = new obj.constructor();
      }
    else
      {
        temp = obj.constructor();
      }

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        obj.isActiveClone = null;
        temp[key] = this.cloneObject(obj[key]);
        delete obj.isActiveClone;
      }
    }
    return temp;
  }
}
