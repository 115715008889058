import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { CacheService } from './cache.service';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of, throwError, zip } from 'rxjs';
import { LoginReq } from '../models/login/loginReq';
import { LoginRes } from '../models/login/loginRes';
import { environment } from '../../environments/environment';
import { Device } from '@capacitor/device';
import { CalendarRes } from '../models/calendar/calendarRes';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  users = null;
  loginUrl = `${environment.apiUrl}/LoginServlet`;
  isEditableBe: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadingController: LoadingController,
    private cacheService: CacheService,
    private http: HttpClient,
    private router: Router
  ) {
  }

  getAuthData(loginName, loginPassword): Observable<LoginRes | null> {
    return zip(
      from(Device.getInfo()),
      from(Device.getId())
    )
      .pipe(
        switchMap(deviceInfo => {
          const login: LoginReq = {
            loginName,
            loginPassword,
            notificationToken: localStorage.getItem('notificationToken'),
            deviceToken: '',
            appName: 'RISPETTO_APP',
            deviceName: deviceInfo[0].name + ' uuid: ' + deviceInfo[1].uuid,
            deviceOs: deviceInfo[0].operatingSystem + ' ' + deviceInfo[0].osVersion
          };

          return this.http.post(this.loginUrl, login
          ).pipe(
            switchMap(result => {
              if (!result) {
                return of(null);
              } else {
                login.loginPassword = '';
                this.cacheService.cacheRequests(this.loginUrl, result, 1000000000).subscribe();
                return of(result);
              }
            })
          );
        })
      );
  }

  deleteAuthData(): Observable<any> {
    this.router.navigate(['/login']);
    this.cacheService.clearCacheData();
    return this.cacheService.invalidateCacheEntry(this.loginUrl);
  }

  isLoggedIn(): Observable<boolean> {
    return this.cacheService.getCachedRequest(this.loginUrl).pipe(
      switchMap(result => {
        if (result?.data?.token) {
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }

  getToken() {
    return this.cacheService.getCachedRequest(this.loginUrl).pipe(
      switchMap(result => {
        if (result?.data?.token) {
          return of(result.data.token);
        } else {
          return of(null);
        }
      }),
      switchMap(token => {
        if (!token) {
          return throwError({
            msg: 'Unauthorized',
            status: 401
          });
        } else {
          return of(token);
        }
      }));
  }

  getIntrauser() {
    return this.cacheService.getCachedRequest(this.loginUrl).pipe(
      switchMap(result => {
        if (result?.data?.token) {
          return of(result.data);
        } else {
          return of(null);
        }
      }));
  }

  getIntrauserId() {
    return this.cacheService.getCachedRequest(this.loginUrl).pipe(
      switchMap(result => {
        if (result?.data?.token) {
          return of(result.data.intrauser_id);
        } else {
          return of(null);
        }
      }));
  }

  setSelectedSubjectId(subjectId) {
    return this.cacheService.cacheRequests('selectedSubject', subjectId, 1000000000);
  }

  getSelectedSubjectId() {
    return this.cacheService.getCachedRequest('selectedSubject').pipe(
      switchMap(result => {
        if (result?.data) {
          return of(result.data);
        } else {
          return of(null);
        }
      }));
  }

  isEditable(calendar?: CalendarRes): Observable<boolean> {
    return zip(
      this.getSelectedSubjectId(),
      this.getIntrauserId(),
      from(Device.getInfo())
    ).pipe(
      switchMap(ids => {
        if (Number(ids[0]) === Number(ids[1])
          && (ids[2].model === 'SM-P610' || ids[2].model === 'SM-P615' || ids[2].platform === 'web')
          /*&& this.isEditableBe*/) {
          if (calendar) {
            const dateParts = calendar.date.split('.');
            const calendarDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
            const actDate = new Date();
            actDate.setHours(0, 0, 0, 0);
            if (actDate > calendarDate) {
              return of(false);
            }
            return of(true);
          } else {
            return of(true);
          }
        } else {
          return of(false);
        }
      })
    );
  }

  isEditableOrders(calendar?: CalendarRes): Observable<boolean> {
    return zip(
      this.getSelectedSubjectId(),
      this.getIntrauserId(),
      from(Device.getInfo())
    ).pipe(
      switchMap(ids => {
        if (Number(ids[0]) === Number(ids[1])
          && (ids[2].model === 'SM-P610' || ids[2].model === 'SM-P615' || ids[2].platform === 'web')
          /*&& this.isEditableBe*/) {
          if (calendar) {
            const dateParts = calendar.date.split('.');
            const calendarDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
            const actDate = new Date();
            actDate.setHours(0, 0, 0, 0);
            if (actDate > calendarDate) {
              actDate.setDate(actDate.getDate() - 1);
              const actTime = new Date();
              if ((actDate.toDateString() === calendarDate.toDateString()) && (actTime.getHours() < 7)) {
                return of(true);
              } else {
                return of(false);
              }
            } else {
              return of(true);
            }
          } else {
            return of(true);
          }
        } else {
          return of(false);
        }
      })
    );
  }

  isEditableRecommendations(calendar?: CalendarRes): Observable<boolean> {
    return zip(
      this.getSelectedSubjectId(),
      this.getIntrauserId(),
      from(Device.getInfo())
    ).pipe(
      switchMap(ids => {
        if (Number(ids[0]) === Number(ids[1]) && (ids[2].model === 'SM-P610' || ids[2].model === 'SM-P615' || ids[2].platform === 'web') /*&& this.isEditableBe*/) {
          if (calendar) {
            const dateParts = calendar.date.split('.');
            const calendarDate = new Date(Number(dateParts[2]), Number(dateParts[1]), Number(dateParts[0]));
            calendarDate.setMonth(calendarDate.getMonth() + 1);
            const actDate = new Date();
            actDate.setHours(0, 0, 0, 0);
            if (actDate > calendarDate) {
              return of(false);
            }
            return of(true);
          } else {
            return of(true);
          }
        } else {
          return of(false);
        }
      })
    );
  }

}
